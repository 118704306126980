import './cookie-control-config';

const pluckIdx = (key, value, arr) => {

  let index = -1;
  arr.forEach((obj, idx) => {
		if (obj[key] === value) index = idx;
	});
	return index;

}

export class CookieControlConsent {

	constructor(consentCategory, cookieNames=[], onAccept, onRevoke, forceConsentOn=true) {
		// TODO: check cookie consent category exists
		// TODO: add cookie names to cookieConfig
		this.categoryConfigObj = this.getCategoryConfigObj(consentCategory);
		this.forceConsentOn = forceConsentOn;

		if(!this.categoryConfigObj) {
			console.error(`CookieControlConsent: Invalid consent category ${consentCategory}`);
			return;
		}

		// save prop values
		this.consentCategory = consentCategory;
		this.cookieNames = cookieNames;

		// add cookieNames to CookieControl config
		this.categoryConfigObj.cookies = this.categoryConfigObj.cookies.concat(cookieNames);

		this.bindEvents({
			onAccept: onAccept,
			onRevoke: onRevoke,
		});

	}

	bindEvents(eventsHash) {

		for(let [eventKey, func] of Object.entries(eventsHash)) {
			if(typeof func === 'function') {
				jQuery('body').on(`CookieControl-${eventKey}.${this.consentCategory}`, func);
			}
		}

		if (this.forceConsentOn) {

      if (document.querySelector('body').dataset.cccloaded) { // ui is already loaded, below event has already fired
        this._forceConsent();
      } else {
        jQuery('body').on('ccc-loaded', _ => this._forceConsent());
      }

			jQuery('body').on(`CookieControl-onAccept.${this.consentCategory}`, _ => {

        // unset the revoked cookie
				if (document.cookie.indexOf(`${this.consentCategory}-revoked`) > -1)
					document.cookie = `${this.consentCategory}-revoked =; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
				// console.log(`${this.consentCategory} cookies accepted`);

			});
			jQuery('body').on(`CookieControl-onRevoke.${this.consentCategory}`, _ => {

        // set the revoked cookie
				document.cookie = `${this.consentCategory}-revoked=1; expires=Thu, 01 Jan 2099 00:00:01 UTC; path=/;`;
				// console.log(`${this.consentCategory} cookies revoked`);

		 });

	  }

	}

	getCategoryConfigObj(consentCategory) {
		return window.cookieControlConfig.optionalCookies.find(obj => obj.name == consentCategory);
	}

	_forceConsent() {

		// if cookies are already accepted or revoked, we don't need to force them
		if (document.cookie.indexOf(this.consentCategory) > -1 || document.cookie.indexOf(`${this.consentCategory}-revoked`) > -1) return;

		this.categoryIndex = pluckIdx('name', this.consentCategory, window.cookieControlConfig.optionalCookies);

    if (this.categoryIndex < 0) return;

		jQuery('body').trigger(`CookieControl-onAccept.${this.consentCategory}`); // fires the category callback
		CookieControl.toggleCategory(this.categoryIndex);                         // drops the cookies and updates UI

	}

}
